import React from "react"
import styled from "styled-components"

const HamburgerLine = styled.div`
  width: 35px;
  height: 5px;
  background-color: var(--blue);
  margin: 6px 0;
`

const HamburgerButton = styled.button`
  cursor: pointer;
  padding: 5px;
  background-color: white;
  border: none;
  @media(min-width: 768px) {
    display: none;
  }
  :focus {
    outline: none;
  }
`

const HamburgerIcon = ({showMobileMenu}) => {
  return (
    <HamburgerButton onClick={showMobileMenu} aria-label="menu">
      <HamburgerLine />
      <HamburgerLine />
      <HamburgerLine />
    </HamburgerButton>
  )
}

export default HamburgerIcon
