import React, { useState } from "react"
import Nav from "./Nav";
import logo from "../../images/logo.png";
import MenuList from "./MenuList";
import ImageResponsive from "../GlobalStyles/ImageResponsive";
import HamburgerIcon from "./HamburgerIcon";
import { BackdropStyled } from "../Contact/ConfirmationModal";
import SideDrawerStyled from "./SideDrawer"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const MainMenu = () => {
  const [showMenu, setShowMenu] = useState(false);

  const showMobileMenu = () => {
    setShowMenu(true);
  }

  const hideMobileMenu = () => {
    setShowMenu(false);
  }

  return (
    <Nav>
      <ImageResponsive src={logo} alt="logo"/>
      <HamburgerIcon showMobileMenu={showMobileMenu} />
      <MenuList>
        <li><AnchorLink to="/#about" title="about">O firmie</AnchorLink></li>
        <li><AnchorLink to="/#offer" title="offer">Nasza oferta</AnchorLink></li>
        <li><AnchorLink to="/#contact" title="contact">Kontakt</AnchorLink></li>
      </MenuList>
      {showMenu ? <BackdropStyled onClick={hideMobileMenu}/> : null}
      <SideDrawerStyled className={showMenu ? "open" : "close"}>
        <ul>
          <li onClick={hideMobileMenu}><AnchorLink to="/#about" title="about">O firmie</AnchorLink></li>
          <li onClick={hideMobileMenu}><AnchorLink to="/#offer" title="offer">Nasza oferta</AnchorLink></li>
          <li onClick={hideMobileMenu}><AnchorLink to="/#contact" title="contact">Kontakt</AnchorLink></li>
        </ul>
      </SideDrawerStyled>
    </Nav>
  )
}

export default MainMenu;
