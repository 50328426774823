import styled from "styled-components";
import SecondBox from "./../../images/second_box.png"
import SecondBoxMobile from "./../../images/second_box_mobile.png"

export const AboutUsHeader = styled.h2`
  font-size: 3.6rem;
  color: var(--secondary-blue);
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  @media(max-width: 767px) {
    font-size: 2.5rem;
  }
`;

export const AboutUsPrimaryText = styled.p`
  font-size: 1.7rem;
  color: var(--text);
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
`;

export const AboutUsSecondaryText = styled.p`
  font-size: 1.5rem;
  color: var(--text);
  font-weight: 400;
  text-align: center+;
`;

export const SecondBoxImage = styled.div`
  background-image: url(${SecondBox});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--yellow);
  height: 200px;
  max-width: 100%;
  
  @media (max-width: 767px) {
    max-width: calc(100% + 16px);
    background-image: url(${SecondBoxMobile});
    margin: 0 -8px;
  }
`;
