import React from "react"
import styled from "styled-components"
import Button from "../GlobalStyles/Button"

export const ModalStyled = styled.div`
  position: fixed;
  z-index: 500;
  text-align: center;
  font-size: 1.5rem;
  background-color: white;
  width: 70%;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  border-radius: 25px;
  padding: 16px;
  left: 15%;
  top: 30%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;

  @media (min-width: 600px) {
    .Modal {
      width: 500px;
      left: calc(50% - 250px);
    }
  }
`

export const BackdropStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  left: 0;
  top: 0;
`

const ConfirmationModal = ({ showModal, closeModal, children }) => {
  return (
    <>
      {showModal ? <BackdropStyled/> : null }
      <ModalStyled style={{
        transform: showModal ? "translateY(0)" : "translateY(-100vh)",
        opacity: showModal ? "1" : "0"
      }}>
        <p>{ children }</p>
        <Button type="button" onClick={closeModal}>Zamknij</Button>
      </ModalStyled>
    </>
  )
}

export default ConfirmationModal

