import styled from "styled-components";

const SubTitle = styled.h2`
  font-size: 4.8rem;
  color: white;
  text-transform: uppercase;
  margin: 0;
  @media (max-width: 600px) {
    font-size: 3rem;
  }
`;

export default SubTitle;
