import React from "react"
import { Helmet } from "react-helmet"

const SiteHeader = () => {
    return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Geopunkt Paweł Chruściel</title>
          <link rel="canonical" href="https://geopunkt.net/" />
          <meta name="description" content="Geodezja Geopunkt Paweł Chruściel" />
          <meta name="keywords" content="geodezja, geopunkt, krasnystaw, krzywe, paweł chruściel" />
          <meta name="author" content="sunokuro Łukasz Kister" />
          <html lang="pl" />
        </Helmet>
    )
}

export default SiteHeader;
