import styled from "styled-components"

const InputContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  label {
    position: absolute; 
    left: -999em;
  }
`;

export default InputContainer
