import React from "react"
import MainContainer from "../GlobalStyles/MainContainer"
import { AboutUsHeader } from "../AboutUs/AboutUsStyles"
import ImageResponsive from "../GlobalStyles/ImageResponsive"
import offer1 from "../../images/offer_1.png"
import offer2 from "../../images/offer_2.png"
import offer3 from "../../images/offer_3.png"
import {OfferList, OfferSectionContainer, OfferWrapper, OffersContainer} from "./OfferStyles"

const Offer = () => {

  return (
    <OfferSectionContainer>
      <MainContainer id="offer">
        <AboutUsHeader>
          Zrealizujemy zlecenia w zakresie
        </AboutUsHeader>
        <OffersContainer>
          <OfferWrapper>
            <ImageResponsive src={offer1} alt="offer-image"/>
            <h1>Geodezja ogólna</h1>
            <OfferList>
              <li>mapy do celów projektowych</li>
              <li>wytyczenia obiektów budowlanych (budynki, sieci, przyłącza)</li>
              <li>geodezyjną inwentaryzację obiektów budowlanych (budynki, sieci, przyłącza) </li>
              <li>pomiary sytuacyjno-wysokościowe</li>
            </OfferList>
          </OfferWrapper>
          <OfferWrapper>
            <ImageResponsive src={offer2} alt="offer-image"/>
            <h1>Geodezja dla nieruchomości</h1>
            <OfferList>
              <li>podziały nieruchomości</li>
              <li>map do celów prawnych</li>
              <li>wznowienia znaków granicznych</li>
              <li>wyznaczenia punktów granicznych</li>
              <li>ustalenia przebiegu granic działek ewidencyjnych </li>
              <li>wyłączenie gruntów z produkcji rolnej</li>
            </OfferList>
          </OfferWrapper>
          <OfferWrapper>
            <ImageResponsive src={offer3} alt="offer-image"/>
            <h1>Usługi UAV - dronem</h1>
            <p>Realizujemy wykonywanie prac przy pomocy bezzałogowego statku powietrznego – powszechnie nazywanego dronem takie jak:</p>
            <OfferList>
              <li>inspekcje i dokumentację fotograficzną lub filmową: obszarów, obiektów, przebiegu prac budowlanych</li>
              <li>ortofotomozaiki</li>
              <li>numeryczne modele terenu</li>
              <li>pomiary powierzchni i objętości terenu</li>
            </OfferList>
          </OfferWrapper>
        </OffersContainer>
      </MainContainer>
    </OfferSectionContainer>
  )
}

export default Offer;
