import styled from "styled-components";

const MainContainer = styled.div`
  max-width: 1140px;
  margin: auto;
  
  @media(max-width: 767px) {
    padding: 0 8px;
  }
`;

export default MainContainer;
