import styled from "styled-components";

export const OfferList = styled.ul`
  list-style: none;
  padding-left: 20px;
  li {
    font-size: 1.5rem;
  }
  li:before {
    content: "\\2022";
    color: var(--yellow); 
    font-weight: bold;
    display: inline-block;
    font-size: 2rem;
    width: 1em;
    margin-left: -1em;
  }
`;

export const OffersContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

export const OfferSectionContainer = styled.section`
  background-color: var(--grey);
  padding-top: 10px;
  padding-bottom: 40px;
`;

export const OfferWrapper = styled.div`
  max-width: 360px;
  p {
    font-size: 1.5rem;
  }
`;
