import React from "react";
import MainContainer from "../GlobalStyles/MainContainer"
import EuLogos from "./EuLogos"
import ImageResponsive from "../GlobalStyles/ImageResponsive"
import euLogo from "../../images/ue_logo.png"
import leaderLogo from "../../images/leader_logo.png"
import lgdLogo from "../../images/lgd_logo.png"
import prowLogo from "../../images/prow_logo.png"
import EuText from "./EuText"

const EuLogosSection = () => {
  return (
    <div style={{ "borderBottom": "1px solid #E8E8E8", "minHeight": "25vh", "borderTop": "1px solid #E8E8E8" }}>
      <MainContainer>
        <EuLogos>
          <div className="margin5">
            <ImageResponsive src={euLogo} alt="eulogo"/>
          </div>
          <div className="margin5">
            <ImageResponsive src={leaderLogo} alt="eulogo"/>
          </div>
          <div className="margin5">
            <ImageResponsive src={lgdLogo} alt="eulogo"/>
          </div>
          <div className="margin5">
            <ImageResponsive src={prowLogo} alt="eulogo"/>
          </div>
        </EuLogos>
        <EuText>Operacja mająca na celu „Utworzenie przedsiębiorstwa Usługi geodezyjne Paweł Chruściel „GEOPUNKT”
          współfinansowana jest ze środków Unii Europejskiej w ramach działania: 19.2 „Wsparcie na wdrażaniem operacji
          w ramach rozwoju lokalnego kierowanego przez społeczność” Programu Rozwoju Obszarów Wiejskich na lata
          2014-2020. </EuText>
        <EuText>Przewidywane wyniki operacji: utworzenie firmy świadczącej usługi geodezyjne, osiągnięcie zysku z
          przedsięwzięcia oraz utworzenie jednego miejsca pracy. </EuText>
      </MainContainer>
    </div>
  )
}

export default EuLogosSection;
