import styled from "styled-components";

const Button = styled.button`
  width: 225px;
  height: 48px;
  font-size: 1.4rem;
  border-radius: 10px;
  background-color: var(--yellow);
  border: none;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s;
  :focus {
    outline: none;
  }
  :hover {
    background-color: #c2a518;
  }
`;

export default Button;
