import styled from "styled-components";

const ContactDetailsIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 207px;
  min-height: 156px;
  p {
    color: white;
    font-size: 1.5rem;
    margin: 5px 0 0 0;
    text-align: center;
  }
  a {
  text-decoration: none;
  color: white;
    font-size: 1.5rem;
    margin: 5px 0 0 0;
    text-align: center;
  }
  
  @media(max-width: 767px) {
    min-width: auto;
    min-height: auto;
    .icon {
      font-size: 3rem;
      
    }
  }
  
`;

export default ContactDetailsIcon;
