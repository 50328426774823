import styled from "styled-components"

const SideDrawerStyled = styled.div`
  position: fixed;
  min-width: 280px;
  max-width: 80%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 200;
  background-color: white;
  padding: 32px 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
  @media (min-width: 767px) {
    display: none;
  }
  &.open {
    transform: translateX(0);
  }
  &.close {
    transform: translateX(+100%);
  }
  ul {
    display: flex;
    list-style: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    li {
     text-transform: uppercase;
    font-weight: 700;
    margin-top: 15px;
    font-size: 2.5rem;
    position: relative;
    display: inline-block;
    ::after {
      content: "";
      position: absolute;
      left:0;
      top: 100%;
      width: 0;
      height: 3px;
      background-color: var(--blue);
      transition: width .3s ease-in-out;
    }
    :hover:after {
      width: 100%;
    }
    a {
      text-decoration: none;
      color: var(--blue);
    }
    }
  }
`

export default SideDrawerStyled
