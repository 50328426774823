import styled from "styled-components";

const MenuList = styled.ul`
  display: flex;
  list-style: none;
  
  li {
    text-transform: uppercase;
    font-weight: 700;
    margin-left: 15px;
    font-size: 1.5rem;
    position: relative;
    display: inline-block;
    ::after {
      content: "";
      position: absolute;
      left:0;
      top: 100%;
      width: 0;
      height: 3px;
      background-color: var(--blue);
      transition: width .3s ease-in-out;
    }
    :hover:after {
      width: 100%;
    }
    a {
      text-decoration: none;
      color: var(--blue);
    }
  }
  
  @media(max-width: 767px) {
    display: none;
  }
  
`;

export default MenuList;
