import styled from "styled-components"
import MainBox from "../../images/main_box.png"
import MainBoxMobile from "../../images/main_box_mobile.png"


const MainSectionBackground = styled.section`
  background-image: url(${MainBox});
  background-position: center;
  background-repeat: no-repeat;
  //background-attachment: fixed;
  background-size: cover;
  background-color: var(--grey);
  height: 548px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 767px) {
    background-image: url(${MainBoxMobile});
  }
  
`

export default MainSectionBackground
