import styled from "styled-components";

const ImageResponsive = styled.img`
  //width: 100%;
  max-width: 100%;
  height: auto;
  object-fit: scale-down;
  margin: ${props => props.margin || 0}
  
  //@media(max-width: 600px) {
  ////  display: none;
  //width: 80%;
  //  max-width: 80%;
  //}
`;

export default ImageResponsive;
