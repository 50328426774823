import React from "react";
import { AboutUsHeader, AboutUsPrimaryText, AboutUsSecondaryText, SecondBoxImage } from "./AboutUsStyles";
import ImageResponsive from "../GlobalStyles/ImageResponsive"
import secondBox from "../../images/second_box.png"
import MainContainer from "../GlobalStyles/MainContainer";



const AboutUs = () => {
  return (
    <MainContainer>
      <AboutUsHeader id="about">Kilka słów o nas</AboutUsHeader>
      <AboutUsPrimaryText>Nasza oferta skierowana jest do indywidualnych klientów, projektantów, wykonawców i
        instytucji</AboutUsPrimaryText>
      {/*<ImageResponsive src={secondBox} alt="second-box"/>*/}
      <SecondBoxImage />
      <AboutUsSecondaryText>Firma świadczy kompleksowe usługi geodezyjno-kartograficzne na terenie powiatu
        krasnostawskiego i powiatów ościennych. Posiadamy nowoczesny sprzęt pomiarowy i wieloletnie doświadczenie w
        branży.</AboutUsSecondaryText>
      <AboutUsSecondaryText>Zapewniamy indywidualne i profesjonalne podejście do każdego klienta, świadczymy wysoką
        jakość usług, każde zlecenie traktujemy z pełnym zaangażowaniem.</AboutUsSecondaryText>
      <AboutUsSecondaryText>Chętnie podejmiemy się nietypowego zlecenia geodezyjnego.</AboutUsSecondaryText>
    </MainContainer>
  )
}

export default AboutUs;
