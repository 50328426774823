import styled from "styled-components";
import mapBig from "./../../images/gmap.png";
import mapSmall from "./../../images/gmap_mobile.png";


const GoogleMapImage = styled.div`
  background-image: url(${mapBig});
  //background-position: center;
  background-repeat: no-repeat;
  //background-size: cover;
  background-color: var(--grey);
  height: 499px;
  max-width: 100%;
  
 @media(max-width: 767px) {
    background-image: url(${mapSmall});
    background-position: left;
  }
`;

export default GoogleMapImage;
