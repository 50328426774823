import styled from "styled-components";

const ContactHeader = styled.h2`
  font-size: 3.6rem;
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 0;
  padding-top: 20px;
  padding-bottom: 5px;
  margin-bottom: 0;
  @media(max-width: 767px) {
    font-size: 2.5rem;
    padding-top: 10px;
  }
`;

export default ContactHeader;
