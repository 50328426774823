import React, { useState } from "react"
import Input from "./Input"
import Textarea from "./Textarea"
import Button from "../GlobalStyles/Button"
import InputContainer from "./InputsContainer"
import ConfirmationModal from "./ConfirmationModal"
import ContactErrorMessage from "./ContactErrorMessage"

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const ContactForm = () => {
  const modalTextSuccess = 'Wiadomość została wysłana';
  const modalTextFailure = 'Wiadomość nie została wysłana! Formularz zawiera błędy. Proszę poprawić formularz i spróbować ponownie. Wszystkie pola formularza są wymagane i muszą zawierać conajmniej 3 znaki. Adres email musi być poprawny.';
  const [showModal, setShowModal] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const { name, email, subject, message } = form;
  const [isFormValid, setFormValid] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    formValidation();
    if (isFormValid) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...form })
      })
        .then(() => {
          setShowModal(true);
          setForm({
            name: '',
            email: '',
            subject: '',
            message: ''
          });
        })
        .catch(error => alert(error));
    } else {
      setShowModal(true);
    }
  };

  const formValidation = () => {
    const mailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (name.length < 3 || email.length < 3 || subject.length < 3 || message.length < 3 || !mailRegex.test(email)) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }

  const handleChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value });
    formValidation();
  };

  const closeModal = () => {
    setShowModal(false);

  }


  return (
    <InputContainer onSubmit={handleSubmit}>
      <label htmlFor="name">Imię i nazwisko</label>
      <Input id="name" type="text" placeholder="Imię i nazwisko" name="name" value={name} onChange={handleChange}/>
      <label htmlFor="email">Email</label>
      <Input id="email" type="email" placeholder="Adres @" name="email" value={email} onChange={handleChange}/>
      <label htmlFor="subject">Temat</label>
      <Input id="subject" type="text" placeholder="Temat" name="subject" value={subject} onChange={handleChange}/>
      <label htmlFor="message">Treść</label>
      <Textarea id="message" placeholder="Treść" name="message" value={message} onChange={handleChange}/>
      <Button type="submit">Wyślij wiadomość</Button>
      <ConfirmationModal showModal={showModal} closeModal={closeModal}>{isFormValid ? modalTextSuccess : modalTextFailure}</ConfirmationModal>
    </InputContainer>
  )
};

export default ContactForm;
