import React from "react"
import MainMenu from "../components/Navigation/MainMenu"
import "../styles/colors.css"
import GlobalStyles from "../components/GlobalStyles/GlobalStyles"
import MainContainer from "../components/GlobalStyles/MainContainer"
import Offer from "../components/Offer/Offer"
import Contact from "../components/Contact/Contact"
import Footer from "../components/Footer/Footer"
import GoogleMapImage from "../components/GoogleMap/GoogleMapImage"
import EuLogosSection from "../components/EuSection/EuLogosSection"
import SiteHeader from "../components/SiteHeader/SiteHeader"
import AboutUs from "../components/AboutUs/AboutUs"
import MainSection from "../components/MainSection/MainSection"

export default function Home() {
  return (
    <>
      <SiteHeader />
      <GlobalStyles/>
      <MainContainer>
        <MainMenu/>
      </MainContainer>
      <EuLogosSection />
      <MainSection />
      <AboutUs />
      <Offer/>
      <Contact/>
      <a href="https://goo.gl/maps/GEf9xFAxqkQYCi1Q6" target="_blank" rel="noreferrer" style={{height: "100%"}} aria-label="google map">
        <GoogleMapImage />
      </a>
      <Footer>
        <p>COPYRIGHT&copy; 2020 BY GeoPunkt</p>
      </Footer>
    </>
  )
}
