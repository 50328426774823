import React from "react";
import MainContainer from "../GlobalStyles/MainContainer"
import MainTitle from "./MainTitle"
import SubTitle from "./SubTitle"
import Button from "../GlobalStyles/Button"
import MainSectionBackground from "./MainSectionBackground"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const MainSection = () => {
  return (
    <MainSectionBackground>
      <MainContainer>
        <div className="margin-bottom-10 margin-top-10">
          <MainTitle>Geopunkt Paweł Chruściel</MainTitle>
          <SubTitle>Profesjonalne Usługi Geodezyjne</SubTitle>
          <AnchorLink to="/#offer" aria-label="nasza oferta" title="offer"><Button>Nasza oferta</Button></AnchorLink>
        </div>
      </MainContainer>
    </MainSectionBackground>
  )
}

export default MainSection;
