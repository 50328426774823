import styled from "styled-components";

const Footer = styled.footer`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  p {
    color: var(--footer-text);
    text-align: center;
    font-size: 1.1rem;
    
  }
`;

export default Footer;
