import styled from "styled-components";

const Input = styled.input`
  height: 45px;
  max-width: 975px;
  width: 90%;
  margin: 10px;
  color: white;
  font-size: 1.6rem;
  background-color: rgba(0,0,0, 0.5);
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 0 15px;
  :active {
    border: none;
    outline: none;
  }
  ::placeholder { 
    color: white;
    opacity: 1; 
    font-size: 1.6rem;
  }
`;

export default Input;
