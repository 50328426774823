import styled from "styled-components";

const EuLogos = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;
`;

export default EuLogos;
