import React from "react"
import styled from "styled-components"
import contactImage from "../../images/contact.png"
import contactImageMobile from "../../images/contact_mobile.png"
import MainContainer from "../GlobalStyles/MainContainer"
import ContactHeader from "./ContactHeader"
import ContactIconsContainer from "./ContactIconsContainer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "../../utils/fontawesome"
import ContactDetailsIcon from "./ContactDetailsIcon"
import ContactForm from "./ContactForm"

const ContactSectionContainer = styled.section`
  background-image: url(${contactImage});
  height: 830px;
  @media (max-width: 767px) {
    background-image: url(${contactImageMobile});
  }
`

const ContactText = styled.p`
  font-size: 1.5rem;
  color: white;
  text-align: center;
  margin-top: 0;
  padding-top: 0;
`


const Contact = () => {
  return (
    <ContactSectionContainer>
      <MainContainer id="contact" style={{maxHeight: "830px", overflow: "auto"}}>
        <ContactHeader>Skontaktuj się z nami</ContactHeader>
        <ContactText>Preferowany kontakt telefoniczny i przez pocztę elektroniczną</ContactText>
        <ContactIconsContainer>
          <ContactDetailsIcon>
            <FontAwesomeIcon className="icon" icon="home" size="6x" color="#E9CF4F"/>
            <p>GEOPUNKT Paweł Chruściel</p>
            <p>Krzywe 64</p>
            <p>22-351 Łopiennik Górny</p>
          </ContactDetailsIcon>
          <ContactDetailsIcon>
            <FontAwesomeIcon className="icon" icon="mobile-alt" size="6x" color="#E9CF4F"/>
            <p>+48 574 005 616</p>
          </ContactDetailsIcon>
          <ContactDetailsIcon>
            <FontAwesomeIcon className="icon" icon={["far", "envelope"]} size="6x" color="#E9CF4F"/>
            <a href="mailto:kontakt@geopunkt.net">kontakt@geopunkt.net</a>
          </ContactDetailsIcon>
          <ContactDetailsIcon>
            <FontAwesomeIcon className="icon" icon={["fab", "facebook-square"]} size="6x" color="#E9CF4F"/>
            <a href="https://www.facebook.com/Geopunkt-109819364181083" target="_blank" rel="noreferrer">facebook</a>
          </ContactDetailsIcon>
        </ContactIconsContainer>
        <ContactForm />
        <ContactText>Zgodnie z zasadą 3U(Unikaj, Użyj, Utylizuj) promującą zdrowy dla środowiska styl życia, konsumpcji
          dóbr i traktowania odpadów, preferujemy przekazywanie dokumentacji w kontaktach z klientami i instytucjami w
          wersji elektronicznej.</ContactText>
      </MainContainer>
    </ContactSectionContainer>
  )
}

export default Contact
