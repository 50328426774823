import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faMobileAlt} from "@fortawesome/free-solid-svg-icons/faMobileAlt";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";

library.add(faHome);
library.add(faEnvelope);
library.add(faMobileAlt);
library.add(faFacebookSquare);
